<template>
  <fb-page :bgColor="{background:'#eef7fc'}">
    <!-- header -->
    <fb-header
      slot="header"
      :title="'客户列表'"
      fixed
    >
      <fb-button
        slot="left"
        icon="back"
        @click="$router.back()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <div class="KL_layout">
      <ul
        v-for="(item,index) of results"
        :key="index"
      >
        <li>
          <span>{{item.customerName}}</span>
          <van-icon
            name="edit-data"
            @click="jumpHandel(item)"
          />
        </li>
        <li>
          {{item.briefInf}}
        </li>
        <li
          v-for="(ChildItem,index) of item.caseAttrList"
          :key="index"
          @click="jumpCaseList(item)"
        >
          <span :class="ChildItem.procedureSubjectShort === '原' ? 'yuan' : 'bei'">{{ChildItem.procedureSubjectShort}}</span>
          <span>{{ChildItem.caseName}}</span>
        </li>
      </ul>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'

export default {
  data () {
    return {
      results: []
    }
  },
  async created () {
    try {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: '加载中...'
      })
      const litigantId = this.$route.params.litigantId
      // const caseBase = '//192.168.1.137:10004'
      const response = await this.$axios.get(`${caseBase}/lts/customer/queryCustomer?litigantId=${litigantId}`)
      const { data, code } = response.data
      if (code === '200') {
        this.results = data.customerBriefInfList
        this.$toast.clear()
      }
      if (JSON.stringify(data) === '{}') {
        this.$toast.fail('无数据')
      }
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    jumpHandel (item) {
      // /casedetail/zss/kuhuliebiaoEdit/:id
      this.$router.push({
        name: 'casedetail-kuhuliebiao-edit',
        params: {
          id: item.customerId
        }
      })
      console.log(item)
    },
    jumpCaseList ({ customerId }) {
      this.$router.push({
        name: 'casedetail-anjianliebiao',
        params: {
          id: customerId
        }
      })
      console.log(customerId)
    }
  }
}
</script>

<style lang='stylus'>
.KL_layout {
  padding: 15px;
  height: calc(100vh - 40px);
  overflow: hidden;
  overflow-y: auto;

  & > ul {
    background: #fff;
    box-shadow: 0 0 1px #40B4E9;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;

    & li {
      padding: 5px 10px;
      font-size: 14px;
      color: #333333;

      & span {
        font-size: 14px;
      }
    }

    & li:nth-of-type(2) ~ li {
      background: #F4F4F4;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & li:nth-of-type(2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & li:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.yuan {
  background: #4899fc;
  color: #fff;
  padding: 0 3px;
  border-radius: 5px;
  margin-right: 5px;
}

.bei {
  background: #ed9b44;
  color: #fff;
  padding: 0 3px;
  border-radius: 5px;
  margin-right: 5px;
}
</style>
