var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    { attrs: { bgColor: { background: "#eef7fc" } } },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "客户列表", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "KL_layout" },
        _vm._l(_vm.results, function (item, index) {
          return _c(
            "ul",
            { key: index },
            [
              _c(
                "li",
                [
                  _c("span", [_vm._v(_vm._s(item.customerName))]),
                  _c("van-icon", {
                    attrs: { name: "edit-data" },
                    on: {
                      click: function ($event) {
                        return _vm.jumpHandel(item)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("li", [_vm._v(" " + _vm._s(item.briefInf) + " ")]),
              _vm._l(item.caseAttrList, function (ChildItem, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.jumpCaseList(item)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class:
                          ChildItem.procedureSubjectShort === "原"
                            ? "yuan"
                            : "bei",
                      },
                      [_vm._v(_vm._s(ChildItem.procedureSubjectShort))]
                    ),
                    _c("span", [_vm._v(_vm._s(ChildItem.caseName))]),
                  ]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }